@media only screen and (max-width: 480px) {
    #footer #elements, #footer #social {
      float:none; /* Ensure stacking */
      display:block; /* Ensure stacking */
      text-align:center; /* Ensure horizontal centering of all footer content */
    }

    #footer #elements {
      position:relative;
      padding-top: 1rem;  /* Reserve vertical space for phone number */
    }

    #footer #elements p {
      position:absolute; /* Causes the phone number to position above social buttons */
      top:0;
      left:0;
      right:0;
    }
  }