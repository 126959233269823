.container1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border: 1rem;
  border-color: black !important;
}

button {
  background-color: #4caf50 !important;
  border: none;
  color: wheat !important;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}
